import React, { useState, useEffect } from 'react';
import './css/style.css';
import goldcoast from './images/city-of-goldcoast.png';
import solar from './images/panel-icon.svg';
import gas from './images/gas_generator.svg';
import solargas from './images/panel-gas_generator.svg';
import windmill from './images/windmill.svg';
import PGW from './images/PGW.svg';
import feed from './images/feed-in.svg';
import system from './images/system.svg';
import load from './images/load.svg';
import karit from './images/karit.svg';
import Axios from "axios";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DynamicAssests from './dynamicassests';


function QueryScreen() {
    const [animatedData, setData] = useState({});
    const [batteryData, setBattery] = useState({});
    const [firstRowData, setFirstRow] = useState({});
    const [secondRowData, setSecondRow] = useState({});
    const [guidnames, setGuidName] = useState("");
    var guid = "";
    let isMounted = true;
    useEffect(() => {
        document.title = "KARIT Power Animation";
        fnGraph();
        return () => {
            isMounted = false;
        };
    }, []);
    function fnGraph() {
        var guidArr = process.env.REACT_APP_SECRET_NAME !== undefined ? process.env.REACT_APP_SECRET_NAME.split(',') : [];
        var guidNameArr = process.env.REACT_APP_SECRET_SITENAME !== undefined ? process.env.REACT_APP_SECRET_SITENAME.split(',') : [];
        var i = 0;
        if (guidArr.length > 0 && guidNameArr.length > 0 && isMounted) {
            guid = guidArr[i];
            setGuidName(guidNameArr[i]);
            setDesign();
            fnFirstRow();
            fnSecondRow();
            if (i === (guidArr.length - 1) && i === (guidNameArr.length - 1)) {
                i = 0;
            } else {
                i++;
            }
            setInterval(() => {
                guid = guidArr[i];
                setGuidName(guidNameArr[i]);
                setDesign();
                fnFirstRow();
                fnSecondRow();
                if (i === (guidArr.length - 1) && i === (guidNameArr.length - 1)) {
                    i = 0;
                }
                else {
                    i++;
                }
            }, 60 * 1000);

        }
        return null;
    }
    function setDesign() {
        Axios.get("https://karitdataapi.azurewebsites.net/api/mssql/EnergyDataByGroupNowANIM/group/" + guid + "/x/x/x/x")
            .then((response) => {
                setData({});
                setBattery({});
                var data = response.data;
                Object.keys(data).reduce((result, currentKey) => {
                    if (currentKey === "topRows") {
                        var topRows = data[currentKey];
                        var arrExport = [];
                        var arrBattery = [];

                        for (let i = 0; i < topRows.length; i++) {
                            Object.keys(topRows[i]).reduce((result, currentKey) => {
                                if (result === "exportW") {
                                    arrExport.push(topRows[i]);
                                }
                                if (result === "batteryStore") {
                                    arrBattery.push(topRows[i]);
                                }
                                return null;
                            });
                        }
                        if (isMounted) {
                            let Data = arrExport.reduce((a, b) => {
                                return new Date(a.utcTime) > new Date(b.utcTime) ? a : b;
                            });
                            setData({ Data });
                            let DataBattery = arrBattery.reduce((a, b) => {
                                return new Date(a.utcTime) > new Date(b.utcTime) ? a : b;
                            });
                            setBattery({ DataBattery });
                            fnChangeDot();
                        }

                    }
                    return null;
                });

                return null;
            })
            .catch(function () {
            });
    }
    function fnFirstRow() {
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();
        Axios.get("https://karitdataapi.azurewebsites.net/api/mssql/FinancialSummaryANIM/group/" + guid + "/" + month + "/" + year + "/null/x")
            .then((response) => {
                if (isMounted) {
                    setFirstRow({});
                    var data = response.data;
                    Object.keys(data).reduce((result, currentKey) => {
                        if (currentKey === "topRows") {
                            var topRows = data[currentKey];
                            var Data = topRows[0];
                            setFirstRow({ Data });
                        }
                        return null;
                    });
                }

            })
            .catch(function () {
            });
    }
    function fnSecondRow() {
        var year = new Date().getFullYear();
        var month = new Date().getMonth() + 1;
        Axios.get("https://karitdataapi.azurewebsites.net/api/mssql/FinancialSummaryANIM/group/" + guid + "/" + month + "/" + year + "/market/x")
            .then((response) => {
                if (isMounted) {
                    setSecondRow({});
                    var data = response.data;
                    Object.keys(data).reduce((result, currentKey) => {
                        if (currentKey === "topRows") {
                            var topRows = data[currentKey];
                            var Data = topRows[0];
                            setSecondRow({ Data });
                        }
                        return null;
                    });
                }
            })
            .catch(function () {
            });
    }
    function fnChangeDot() {
        let utc3 = 0;
        let utc15 = 0;
        fnFirstRow();
        fnSecondRow();
        setInterval(() => {
            var hrs = new Date().getUTCHours();
            if ((hrs === 3 && utc3 === 0) || (hrs === 15 && utc15 === 0)) {
                if (hrs === 3 && utc3 === 0) {
                    utc3 = 1;
                }
                if (hrs === 15 && utc15 === 0) {
                    utc15 = 1;
                }
                fnFirstRow();
                fnSecondRow();
            }
            if (document.getElementById("dot01").classList.contains("dot01")) {
                if (document.getElementById("dot01").style.backgroundColor === "rgb(255, 103, 82)") {
                    document.getElementById("dot01").style.backgroundColor = "#f9c3bb";
                } else {
                    document.getElementById("dot01").style.backgroundColor = "#FF6752";
                }
            } else {
                if (document.getElementById("dot01").classList.contains("dot11")) {
                    if (document.getElementById("dot01").style.backgroundColor === "rgb(61, 203, 152)") {
                        document.getElementById("dot01").style.backgroundColor = "#b2fbe1";
                    } else {
                        document.getElementById("dot01").style.backgroundColor = "#3DCB98";
                    }
                }

            }
            if (document.getElementById("dot02").classList.contains("dot02")) {
                if (document.getElementById("dot02").style.backgroundColor === "rgb(255, 103, 82)") {
                    document.getElementById("dot02").style.backgroundColor = "#f9c3bb";
                } else {
                    document.getElementById("dot02").style.backgroundColor = "#FF6752";
                }
            } else {
                if (document.getElementById("dot02").classList.contains("dot12")) {
                    if (document.getElementById("dot02").style.backgroundColor === "rgb(61, 203, 152)") {
                        document.getElementById("dot02").style.backgroundColor = "#b2fbe1";
                    } else {
                        document.getElementById("dot02").style.backgroundColor = "#3DCB98";
                    }
                }

            }
            if (document.getElementById("dot03").classList.contains("dot03")) {
                if (document.getElementById("dot03").style.backgroundColor === "rgb(255, 103, 82)") {
                    document.getElementById("dot03").style.backgroundColor = "#f9c3bb";
                } else {
                    document.getElementById("dot03").style.backgroundColor = "#FF6752";
                }
            } else {
                if (document.getElementById("dot03").classList.contains("dot13")) {
                    if (document.getElementById("dot03").style.backgroundColor === "rgb(61, 203, 152)") {
                        document.getElementById("dot03").style.backgroundColor = "#b2fbe1";
                    } else {
                        document.getElementById("dot03").style.backgroundColor = "#3DCB98";
                    }
                }
            }
            if (document.getElementById("smalldot01").classList.contains("smalldot01")) {
                if (document.getElementById("smalldot01").style.backgroundColor === "rgb(249, 195, 187)") {
                    document.getElementById("smalldot01").style.backgroundColor = "#FF6752";
                } else {
                    document.getElementById("smalldot01").style.backgroundColor = "#f9c3bb";
                }
            } else {
                if (document.getElementById("smalldot01").classList.contains("smalldot11")) {
                    if (document.getElementById("smalldot01").style.backgroundColor === "rgb(178, 251, 225)") {
                        document.getElementById("smalldot01").style.backgroundColor = "#3DCB98";
                    } else {
                        document.getElementById("smalldot01").style.backgroundColor = "#b2fbe1";
                    }
                }
            }
            if (document.getElementById("smalldot02").classList.contains("smalldot02")) {
                if (document.getElementById("smalldot02").style.backgroundColor === "rgb(249, 195, 187)") {
                    document.getElementById("smalldot02").style.backgroundColor = "#FF6752";
                } else {
                    document.getElementById("smalldot02").style.backgroundColor = "#f9c3bb";
                }
            } else {
                if (document.getElementById("smalldot02").classList.contains("smalldot12")) {
                    if (document.getElementById("smalldot02").style.backgroundColor === "rgb(178, 251, 225)") {
                        document.getElementById("smalldot02").style.backgroundColor = "#3DCB98";
                    } else {
                        document.getElementById("smalldot02").style.backgroundColor = "#b2fbe1";
                    }
                }
            }
            if (document.getElementById("smalldot03").classList.contains("smalldot03")) {
                if (document.getElementById("smalldot03").style.backgroundColor === "rgb(249, 195, 187)") {
                    document.getElementById("smalldot03").style.backgroundColor = "#FF6752";
                } else {
                    document.getElementById("smalldot03").style.backgroundColor = "#f9c3bb";
                }
            } else {
                if (document.getElementById("smalldot03").classList.contains("smalldot13")) {
                    if (document.getElementById("smalldot03").style.backgroundColor === "rgb(178, 251, 225)") {
                        document.getElementById("smalldot03").style.backgroundColor = "#3DCB98";
                    } else {
                        document.getElementById("smalldot03").style.backgroundColor = "#b2fbe1";
                    }
                }
            }
            if (document.getElementById("smalldot04").classList.contains("smalldot04")) {
                if (document.getElementById("smalldot04").style.backgroundColor === "rgb(249, 195, 187)") {
                    document.getElementById("smalldot04").style.backgroundColor = "#FF6752";
                } else {
                    document.getElementById("smalldot04").style.backgroundColor = "#f9c3bb";
                }
            } else {
                if (document.getElementById("smalldot04").classList.contains("smalldot14")) {
                    if (document.getElementById("smalldot04").style.backgroundColor === "rgb(178, 251, 225)") {
                        document.getElementById("smalldot04").style.backgroundColor = "#3DCB98";
                    } else {
                        document.getElementById("smalldot04").style.backgroundColor = "#b2fbe1";
                    }
                }
            }
        }, 500);
    }
    return (
        <div className="wrapper">
            <div className="main_div">
                <div className="guidName">  {guidnames} </div>
                <div className="top_logo">
                    <img src={goldcoast} alt="city of goldcoast" /></div>
                <div className="fr_section">
                    <div className="sp_box">
                        <div className="sp_imgbox">
                            {animatedData.Data !== undefined && animatedData.Data.gentype !== undefined
                                ?
                                animatedData.Data.gentype === "Solar" ? <img src={solar} alt="" />
                                    :
                                    animatedData.Data.gentype === "SolarCogen" ? <img src={solargas} alt="" />
                                        :
                                        animatedData.Data.gentype === "Cogen" ? <img src={gas} alt="" />
                                            :
                                            animatedData.Data.gentype === "Wind" ? <img src={windmill} alt="" />
                                                :
                                                animatedData.Data.gentype === "Mixed" ? <img src={PGW} alt="" />
                                                    :
                                                    <img src={solar} alt="" />
                                :
                                <img src={solar} alt="" />
                            }
                            {
                                animatedData.Data !== undefined && animatedData.Data.genW > '0' && Math.ceil(animatedData.Data.genW / 1000) > '0'
                                    ?
                                    <div className="solarmodulearrows">
                                        <div className="solarmodule_fr_line1"></div>
                                        <div className="solarmodule_fr_line2"></div>
                                        <div className="solarmodule_fr_line3"></div>
                                        <div className="solarmodule_fr_arrow"></div>
                                    </div>
                                    :
                                    <div>
                                        <div className="staticlinesolar">
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="sp_head">Generation</div>
                        <div className="greenbox_wrap"><span className="greenbox">{animatedData.Data !== undefined ? animatedData.Data.genW !== undefined && animatedData.Data.genW > 0 ? Math.ceil(animatedData.Data.genW / 1000) + 'kW' : "0" : "0"}</span></div>
                        <div className="sp_head">System Size</div>

                        <div className="greenbox_wrap"><span className="greybox">{animatedData.Data !== undefined ? animatedData.Data.totinvsizeKW !== undefined && animatedData.Data.totinvsizeKW > 0 ? animatedData.Data.totinvsizeKW + 'kW' : "0" : "0"}</span></div>

                    </div>
                    <div className="sp_box flright">
                        <div className="sp_imgbox"><img src={feed} alt="" />
                            {
                                animatedData.Data !== undefined && animatedData.Data.exportW !== '0' && Math.ceil(animatedData.Data.exportW / 1000) !== 0
                                    ?
                                    <div className="feedinarrows">
                                        <div className={animatedData.Data !== undefined ? animatedData.Data.exportW !== undefined && animatedData.Data.exportW >= 0 ? 'feedinup_fr_line1' : 'feedindown_fr_line1' : 'feedinup_fr_line1'}></div>
                                        <div className={animatedData.Data !== undefined ? animatedData.Data.exportW !== undefined && animatedData.Data.exportW >= 0 ? 'feedinup_fr_line2' : 'feedindown_fr_line2' : 'feedinup_fr_line2'}></div>
                                        <div className={animatedData.Data !== undefined ? animatedData.Data.exportW !== undefined && animatedData.Data.exportW >= 0 ? 'feedinup_fr_arrow' : 'feedindown_fr_arrow' : 'feedinup_fr_arrow'}></div>
                                    </div>
                                    :
                                    <div>
                                        <div className="staticlinefeedin"></div>
                                    </div>
                            }
                        </div>
                        <div className="sp_head">{animatedData.Data !== undefined ? animatedData.Data.exportW !== undefined && animatedData.Data.exportW > 0 ? 'Export' : 'Import' : 'NA'}</div>
                        <div className="greenbox_wrap"><span className={animatedData.Data !== undefined ? animatedData.Data.exportW !== undefined && animatedData.Data.exportW >= 0 ? 'greenbox' : 'redbox' : 'greenbox'}>{animatedData.Data !== undefined ? animatedData.Data.exportW !== undefined ? animatedData.Data.exportW !== "0" ? Math.ceil(animatedData.Data.exportW / 1000) + 'kW' : "0" : "0" : "0"}</span></div>

                    </div>
                </div>
                <div className="system_box"><img src={system} alt="" /></div>
                <div className="fr_section sec_section">
                    <div className="sp_box">
                        <div className={batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? "sp_imgbox" : ""} >
                            {batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ?
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '37px' }} width="101" height="182" viewBox="0 0 101 182" fill="none">
                                    <g clipPath="url(#clip0_202_2)">
                                        <rect x="12" y="27" width="78" height="142" fill="white" />
                                        <path d="M90.851 13.9919H11C5.478 13.9919 1 18.4699 1 23.9919V171.08C1 176.601 5.478 181.08 11 181.08H90.852C96.375 181.08 100.852 176.601 100.852 171.08V23.9919C100.851 18.4699 96.375 13.9919 90.851 13.9919ZM90.095 168.705H12.371V27.2299H90.098V168.705H90.095Z" fill="#0091A5" stroke="black" />
                                        <path d="M21.8691 10C21.8691 4.477 26.3471 0 31.8691 0H69.9791C75.5001 0 79.9791 4.477 79.9791 10V13.992H21.8691V10V10Z" fill="#414141" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_202_2">
                                            <rect width="100.852" height="181.58" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                : <></>}
                            <span className="charging2"><span style={animatedData.Data !== undefined && batteryData.DataBattery !== undefined ? batteryData.DataBattery.batteryStore !== undefined ? { 'height': batteryData.DataBattery.batteryStore > 100 ? '100%' : batteryData.DataBattery.batteryStore + '%' } : { 'height': '0' } : { 'height': '0' }} className={(animatedData.Data !== undefined ? animatedData.Data.powerstoreW !== undefined ? animatedData.Data.powerstoreW >= 0 ? 'charginggreen' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '100' ? 'chargingred' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore === '100' ? 'charginggreen' : '' : '' : '')}></span></span>
                            <div className="dotdiv">
                                <span id="smalldot01" className={(batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW < 0 ? 'smalldot01' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW >= 0 ? 'smalldot11' : '')} ></span>
                                <span id="smalldot02" className={(batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW < 0 ? 'smalldot02' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW >= 0 ? 'smalldot12' : '')} ></span>
                                <span id="smalldot03" className={(batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW < 0 ? 'smalldot03' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW >= 0 ? 'smalldot13' : '')} ></span>
                                <span id="smalldot04" className={(batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW < 0 ? 'smalldot04' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW >= 0 ? 'smalldot14' : '')} ></span>
                                <span id="dot01" className={(batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW < 0 ? 'dot01' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW >= 0 ? 'dot11' : '')} ></span>
                                <span id="dot02" className={(batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW < 0 ? 'dot02' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW >= 0 ? 'dot12' : '')}></span>
                                <span id="dot03" className={(batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW < 0 ? 'dot03' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore > 0 && batteryData.DataBattery.batteryStore <= 99.9 && animatedData.Data !== undefined && animatedData.Data.powerstoreW >= 0 ? 'dot13' : '')}></span>
                            </div>

                        </div>
                        {batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? <div className="sp_head">Usable Battery</div> : <></>}
                        <div className="greenbox_wrap"><span className="greenbox">{batteryData.DataBattery !== undefined ? batteryData.DataBattery.batteryStore !== undefined ? batteryData.DataBattery.batteryStore + '%' : "" : ""}</span></div>

                        {batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? <div className="sp_head">Battery Capacity</div> : <></>}
                        <div className="greenbox_wrap"><span className="greybox">{batteryData.DataBattery !== undefined ? batteryData.DataBattery.batterysizekwh !== undefined ? batteryData.DataBattery.batterysizekwh + 'kWh' : "" : ""}</span></div>
                        {/* <div className={animatedData.Data !== undefined ? animatedData.Data.powerstoreW !== undefined && animatedData.Data.powerstoreW >= 0 ? 'battery_leftarrow' : 'battery_rightarrow' : 'battery_leftarrow'}><span className="battery_text"></span>
            </div> */}
                        {
                            batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0'
                                ?
                                <div className={animatedData.Data !== undefined ? animatedData.Data.powerstoreW !== undefined && animatedData.Data.powerstoreW >= 0 ? batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterydownarrows' : '' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batteryuparrows' : '' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterydownarrows' : ''}>
                                    <div className={animatedData.Data !== undefined ? animatedData.Data.powerstoreW !== undefined && animatedData.Data.powerstoreW >= 0 ? batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterydown_fr_line1' : '' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterynup_fr_line1' : '' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterydown_fr_line1' : ''}></div>
                                    <div className={animatedData.Data !== undefined ? animatedData.Data.powerstoreW !== undefined && animatedData.Data.powerstoreW >= 0 ? batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterydown_fr_line2' : '' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterynup_fr_line2' : '' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterydown_fr_line2' : ''}></div>
                                    <div className={animatedData.Data !== undefined ? animatedData.Data.powerstoreW !== undefined && animatedData.Data.powerstoreW >= 0 ? batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterydown_fr_arrow' : '' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterynup_fr_arrow' : '' : batteryData.DataBattery !== undefined && batteryData.DataBattery.batteryStore !== '0' ? 'batterydown_fr_arrow' : ''}></div>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    <div className="sp_box flright">
                        <div className="sp_imgbox"><img src={load} alt="" />
                            {
                                animatedData.Data !== undefined && animatedData.Data.consW > '0' && Math.ceil(animatedData.Data.consW / 1000) > 0
                                    ?
                                    <div className="loadarrows">
                                        <div className="load_fr_line1"></div>
                                        <div className="load_fr_line2"></div>
                                        <div className="load_fr_line3"></div>
                                        <div className="load_fr_arrow"></div>
                                    </div>
                                    :
                                    <div>
                                        <div className="staticlineload"></div>
                                    </div>
                            }
                        </div>
                        <div className="sp_head">Load</div>
                        <div className="greenbox_wrap"><span className="greenbox">{animatedData.Data !== undefined ? animatedData.Data.consW !== undefined && animatedData.Data.consW > 0 ? Math.ceil(animatedData.Data.consW / 1000) + 'kW' : "0" : "0"}</span></div>

                    </div>
                </div>
                <div className="footer_wrap">
                    <div className="footer_logo"><img src={karit} alt="KARIT" /></div>
                </div>
                <div className="table_wrap">
                    <table>
                        <thead>
                            <tr>
                                <th>$ Total Generation </th>
                                <th>$ Total Consumption </th>
                                <th>$ Total Imported Energy </th>
                                <th>$ Total Exp Energy </th>
                                <th>$ Total Battery Import </th>
                                <th>$ Total Battery Export </th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{firstRowData.Data !== undefined ? firstRowData.Data.totalGeneration !== undefined ? firstRowData.Data.totalGeneration : "0" : "0"}</td>
                                <td>{firstRowData.Data !== undefined ? firstRowData.Data.totalConsumption !== undefined ? firstRowData.Data.totalConsumption : "0" : "0"}</td>
                                <td>{firstRowData.Data !== undefined ? firstRowData.Data.totalImportedEnergy !== undefined ? firstRowData.Data.totalImportedEnergy : "0" : "0"}</td>
                                <td>{firstRowData.Data !== undefined ? firstRowData.Data.totalExpEnergy !== undefined ? firstRowData.Data.totalExpEnergy : "0" : "0"}</td>
                                <td>{firstRowData.Data !== undefined ? firstRowData.Data.totalBatteryImport !== undefined ? firstRowData.Data.totalBatteryImport : "0" : "0"}</td>
                                <td>{firstRowData.Data !== undefined ? firstRowData.Data.totalBatteryExport !== undefined ? firstRowData.Data.totalBatteryExport : "0" : "0"}</td>
                                <td>{firstRowData.Data !== undefined ? 'Tariff' : '-'}</td>
                            </tr>
                            <tr>
                                <td>{secondRowData.Data !== undefined ? secondRowData.Data.totalGeneration !== undefined ? secondRowData.Data.totalGeneration : "0" : "0"}</td>
                                <td>{secondRowData.Data !== undefined ? secondRowData.Data.totalConsumption !== undefined ? secondRowData.Data.totalConsumption : "0" : "0"}</td>
                                <td>{secondRowData.Data !== undefined ? secondRowData.Data.totalImportedEnergy !== undefined ? secondRowData.Data.totalImportedEnergy : "0" : "0"}</td>
                                <td>{secondRowData.Data !== undefined ? secondRowData.Data.totalExpEnergy !== undefined ? secondRowData.Data.totalExpEnergy : "0" : "0"}</td>
                                <td>{secondRowData.Data !== undefined ? secondRowData.Data.totalBatteryImport !== undefined ? secondRowData.Data.totalBatteryImport : "0" : "0"}</td>
                                <td>{secondRowData.Data !== undefined ? secondRowData.Data.totalBatteryExport !== undefined ? secondRowData.Data.totalBatteryExport : "0" : "0"}</td>
                                <td>{secondRowData.Data !== undefined ? 'market' : '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div >
    );
}
export default function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    {/* <Route path="/" exact element={<QueryScreen />} /> */}
                    <Route path="/" element={< DynamicAssests />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}
const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);